import React from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"

const getCloudinaryImageUrl = ({ category, name, cloudinaryName }) =>
    `https://res.cloudinary.com/${cloudinaryName}/image/upload/q_auto,f_auto,fl_keep_attribution/${category}/${name}`

function CloudinaryImage({ category, name, alt, className }) {
    const cloudinaryName = process.env.GATSBY_CLOUDINARY_CLOUD_NAME
    const url = getCloudinaryImageUrl({ category, name, cloudinaryName })

    return (
        <LazyLoadImage
            alt={alt}
            src={url}
            className={className}
            effect="opacity"
            wrapperClassName="image-wrapper"
        />
    )
}

CloudinaryImage.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    category: PropTypes.string.isRequired,
    alt: PropTypes.string,
}

export default CloudinaryImage
