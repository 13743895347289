import React from "react"
import "../css/about.scss"
import Layout from "../components/layout/layout"
import CloudinaryImage from "../components/cloudinary-image/cloudinary-image"
import { GlobalHead } from "../helpers/helpers"

function PageContent() {
    return (
        <div className="about__container">
            <CloudinaryImage
                category="rm-photos"
                name="me"
                className="about__image"
                alt="Me looking across some water at some hills"
            />
            <div className="about__text">
                <div className="about__text--me">
                    <h1 className="about__text--title">About me</h1>
                    <p>
                        Hi, I&#39;m Rich, a web developer and amateur
                        photographer from Cardiff, Wales. I&#39;ve always liked
                        photography but I got my first serious camera in 2012.
                        <br />
                        <br />
                        One of the main things I enjoy alongside photography is
                        traveling, which is why the main style of photography I
                        focus on is travel. The camera I use is a Canon 550D,
                        usually with my go to lens, a Sigma 17-50mm f2.8. One of
                        my proudest moments was having one of my images featured
                        in National Geographic Traveler magazine.
                        <br />
                        <br />I hope you enjoy my images and if you are
                        interested in getting in touch about my photography,
                        purchasing prints, potential work or just to say hi then
                        please do with the contact form below.
                        <br />
                        <br />
                        Or you can email me at{" "}
                        <span className="noWrap">
                            <a
                                className="link"
                                href="mailto:hello@richmccarthyphoto.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                hello@richmccarthyphoto.com
                            </a>
                            .
                        </span>
                    </p>
                </div>
                <form
                    method="POST"
                    // eslint-disable-next-line react/no-unknown-property
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    name="contact"
                    className="contact__form"
                    action="/success"
                >
                    <input type="hidden" name="bot-field" />
                    <table>
                        <tbody>
                            <tr className="contact__form--field">
                                <td>
                                    <label
                                        className="contact__form--label"
                                        htmlFor="name"
                                    >
                                        Name
                                    </label>
                                </td>
                                <td>
                                    <input
                                        id="name"
                                        className="contact__form--input"
                                        type="text"
                                        name="name"
                                        placeholder="Your name"
                                    />
                                </td>
                            </tr>

                            <tr className="contact__form--field">
                                <td>
                                    <label
                                        className="contact__form--label"
                                        htmlFor="email"
                                    >
                                        Email Address
                                    </label>
                                </td>
                                <td>
                                    <input
                                        id="email"
                                        className="contact__form--input"
                                        type="text"
                                        name="email"
                                        placeholder="Your email address"
                                        required
                                    />
                                </td>
                            </tr>

                            <tr className="contact__form--field">
                                <td>
                                    <label
                                        className="contact__form--label contact__form--message-label"
                                        htmlFor="message"
                                    >
                                        Message
                                    </label>
                                </td>
                                <td>
                                    <textarea
                                        id="message"
                                        className="contact__form--input contact__form--message"
                                        name="message"
                                        rows="8"
                                        cols="25"
                                        placeholder="Your message"
                                        required
                                    />
                                </td>
                            </tr>
                            <tr className="contact__form--field">
                                <td />
                                <td>
                                    <button
                                        className="contact__form--button"
                                        type="submit"
                                        value="Send"
                                    >
                                        <span className="contact__form--button--inner">
                                            Send
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}

export function Head() {
    return <GlobalHead></GlobalHead>
}

export default function Contact() {
    return <Layout>{PageContent()}</Layout>
}
